$theme-colors: (
        "primary": black,
        "info": tomato,
        "danger": teal
);

@import "~bootstrap/scss/bootstrap";

.app-logo {
    width: 10rem;
    height: auto;
}